<template>
  <div class='template'>    
      <article class="images">
          <div :style="{backgroundImage:`url(${page?.background})`}" />              
      </article>    
      <article class='text-content'>
          <ImageTitle transparent :title="page.title"/>  
          <div class='text-content-limit-width'>
            <transition name="slide" appear>
                <div>
                    <img src="@/assets/images/placeholder_logo_car-asphalt.png" alt="" class='track-logo'>    
                    <div>
                        <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
                        <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                        <img v-if="fixedImage" :src="fixedImage.file" alt="" class='fixed-image'>    
                    </div>                   
                </div>
            </transition>
          </div>
          <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
          </div>
      </article>      
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Track Logo Fixed Image',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:1,
            copy:this.page.copy     
        }
    },
    computed: {  
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        fixedImage() {            
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
               return {
                    file:element.file,
                    title:element.title
                };
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        align-items: center;   
        position: relative;            
        grid-template-rows: minmax(200px, 1fr) minmax(60vh, max-content);
    } 
    .ui {
        margin-bottom:20px;
    }   
    .text-content {
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;
        display: grid;        
        height:100%;
        position: relative;    
        padding:30px;
        box-sizing: border-box;     
        background: linear-gradient(134.76deg, #202020 0%, #121212 100%);              
        padding-top:70px;     
        .copy {
            margin-top:18px;
            margin-bottom: 40px;
        }            
        @media (min-width:700px) {
            padding-top:0;     
            align-items: flex-start;
            justify-items: center;
            background:url('~@/assets/images/bg_texture.jpg');
            background-size: cover;
            .text-content-limit-width {
                max-width:1000px;
                margin:0 auto;
            }
            .text-content-limit-width > div:not(.title) {
                position: relative;
                > div {
                    position: relative;
                }
                &:before {
                    content:"";
                    position: absolute;
                    top:0;
                    left:0;
                    bottom:50px;
                    width:100%;                    
                    border:1px solid #0066CC;
                    border-top:none;
                    margin-top:120px;
                    box-sizing: border-box;
                }
            }
            .text-content-limit-width > div {
                display:grid;
                grid-template-columns: 220px 1fr;    
                gap:50px;
                padding:0 50px;
                > div:not(.copy) {                    
                    max-width: 550px;
                    padding-right:0;
                    margin-left:auto;
                    width:100%;
                }
            
            }
        }
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }   
    .images {        
        display:grid;
        gap:1px;               
        height:100%;  
        position: relative;              
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;    
            position: relative;        
        }                  
    }
    .track-logo {
        position: absolute;
        right:20px;
        top:0;
        width:130px;
        transform:translateY(-50%);
        box-sizing: border-box;
        @media (min-width:700px) {
            position: static;
            width:100%;        
            margin-top:-50px;
            transform: translateY(0);
        }
    }
    .fixed-image {
        display: block;
        width:inherit;
        max-width: calc(100vw - 60px);
    }
    .text-content .title {
        position: absolute;        
        top:0;
        left:0;
        text-transform: uppercase;
        padding:30px;
    }  
</style>